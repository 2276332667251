"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
module.exports = {
    'stock-order.list.main.title': 'B2B megrendelések',
    'b2b-order.list.create.button': 'Rendelés létrehozása',
    'b2b-order.table.header.total': 'Total',
    'b2b-order.table.header.identifier': 'Azonosító',
    'b2b-order.table.header.customerPartner': 'vevő cég',
    'b2b-order.table.header.status': 'státusz',
    'b2b-order.table.header.createdAt': 'létrehozva',
    'b2b-order.table.header.updatedAt': 'módosítva',
    'b2b-order.table.header.plannedDeliveryDate': 'tervezett szállítás',
    'b2b-order.table.header.city': 'cél város',
    'b2b-order.filter.form.label.customer-partner': 'Vevő cég',
    'b2b-order.filter.form.label.status': 'Státusz',
    'b2b-order.filter.form.label.from': '-tól',
    'b2b-order.filter.form.label.to': '-ig',
    'b2b-order.filter.label.manage.product': 'Termék kezelés',
    'b2b-order.filter.form.label.is-plan-update-needed': 'Módosítás szükséges',
    'b2b-order.filter.form.label.is-invoice-issued': 'Számla kiállítva',
    'order.filter.form.label.hasAffiliateDiscount': 'Csak affiliate vásárlások',
    'b2b-order.status.draft': 'rögzítés alatt',
    'b2b-order.status.financeApprovalNeeded': 'pénzügyi jóváhagyásra vár',
    'b2b-order.status.financeApproved': 'pénzügy jóváhagyta',
    'b2b-order.status.stockLocked': 'rendelés lefoglalva',
    'b2b-order.status.casePicked': 'rendelés kiszedve',
    'b2b-order.status.deliveryNoteIssued': 'rendelés teljesítve',
    'b2b-order.status.factDeliveryDetermined': 'rendelés számlázva',
    'b2b-order.status.paid': 'fizetve',
    'b2b-order.status.canceled': 'törölt megrendelés',
    'b2b-order.create.title': 'Megrendelés',
    'b2b-order.create.form.missing.required': 'Kötelező adat',
    'b2b-order.update.title': 'Megrendelés',
    'b2b-order.form.label.customer-partner': 'Vevő cég',
    'b2b-order.form.label.created-by': 'Létrehozó',
    'b2b-order.form.label.shipping-data': 'Telephely',
    'b2b-order.form.label.comment': 'Megjegyzés',
    'b2b-order.form.label.customOrderNumber': 'Rendelésszám',
    'b2b-order.form.label.goodsReceiptId': 'Áruátvételi bizonylat szám',
    'b2b-order.form.label.publicComment': 'Publikus Megjegyzés (logisztika felé)',
    'b2b-order.form.label.isForeign': 'EMCS',
    'b2b-order.form.label.isFreeShipping': 'Ingyenes szállítás',
    'b2b-order.form.label.warehouse': 'Raktár',
    'b2b-order.form.label.isPlanUpdateNeeded': 'Terv módosítás szükséges',
    'b2b-order.form.label.plannedDeliveryDate': 'Tervezett kiszállítás',
    'b2b-order.form.label.licensePlateNumber': 'Kiszállító rendszáma',
    'b2b-order.form.label.cancelReason': 'Sztornó indoklás',
    'b2b-order.form.label.issuingPurpose': 'Kiszállítás célja',
    'b2b-order.create.product-list.title': 'Termékek',
    'b2b-order.create.product-list.add.button': 'Új termék hozzáadása',
    'b2b-order.create.table.header.product': 'Termék',
    'b2b-order.create.table.header.lot': 'lot/nébih',
    'b2b-order.create.table.header.bestPrice': 'legjobb ár',
    'b2b-order.create.table.header.unit': 'egység',
    'b2b-order.create.table.header.exactPrice': 'egyedi ár',
    'b2b-order.create.table.header.plan': 'rendelt',
    'b2b-order.create.table.header.casePicked': 'lefoglalt / kiszedett (db)',
    'b2b-order.create.table.header.fact': 'átvett (db)',
    'b2b-order.create.table.header.delete': React.createElement("i", { className: 'fas fa-trash' }),
    'b2b-order.approval-needed.reason': 'Hitelkeret túllépés',
    'b2b-order.approval-needed.credit': 'Hitelkeret',
    'b2b-order.approval-needed.difference': 'Túllépés mértéke',
    'b2b-order.create.success.toast': 'Sikerese rendelés létrehozás',
    'b2b-order.create.error.toast': 'Sikertelen rendelés létrehozás',
    'b2b-order.set.status.success.toast': 'Sikerese státusz módosítás',
    'b2b-order.set.status.error.toast': 'Sikertelen státusz módosítás',
    'b2b-order.update.success.toast': 'Sikeres rendelés módosítás',
    'b2b-order.update.error.toast': 'Sikertelen rendelés módosítás',
    'b2b-order.update.error.toast.MissingLegalCode': 'Jogcímkód hiányzik',
    'b2b-order.update.error.toast.MissingSupplierPrice': 'Hiányzó beszállítói ár',
    'b2b-order.cancel.success.toast': 'Sikerese rendelés sztornózás',
    'b2b-order.cancel.error.toast': 'Sikertelen rendelés sztornózás',
    'b2b-order.delete-draft.success.toast': 'Sekeres vázlat törlés',
    'b2b-order.delete-draft.error.toast': 'Sikerleten vázlat törlés',
    'b2b-order.update.order.error.toast': 'Sikerleten rendelés felnyitás',
    'b2b-order.update.order.success.toast': 'Sikeres rendelés felnyitás',
    'b2b-order.swap-product.modal.title': 'Termék cseréje',
    'b2b-order.add-product.modal.title': 'Termék hozzáadás',
    'b2b-order.add-product.modal.form.field.product.label': 'Termék',
    'b2b-order.add-product.modal.close.button': 'Mégse',
    'b2b-order.add-product.modal.add-product.button': 'Hozzáadás',
    'b2b-order.delete-product.modal.title': 'Termék eltávolítása',
    'b2b-order.delete-product.modal.text': 'Biztos, hogy törlöd a terméket?',
    'b2b-order.delete-product.modal.close.button': 'Mégse',
    'b2b-order.delete-product.modal.add-product.button': 'Törlés',
    'b2b-order.cancel.modal.title': 'Megrendlés szotnózása',
    'b2b-order.form.label.cancel-reason': 'Indoklás',
    'b2b-order.cancel.modal.close.button': 'Mégse',
    'b2b-order.cancel.modal.cancel.button': 'Sztornó',
    'b2b-order.modify.order.modal.title': 'Rendelés felnyitása',
    'b2b-order.modify.order.modal.comment': 'Megjegyzés',
    'b2b-order.modify.order.modal.submit': 'Mentés',
    'b2b-order.delete-draft.modal.title': 'Vázlat törlése',
    'b2b-order.delete-draft.modal.text': 'Biztosan törölni akarod avázlatot?',
    'b2b-order.delete-draft.modal.close.button': 'Mégse',
    'b2b-order.delete-draft.modal.add-product.button': 'Törlés',
    'b2b-order.product.is.not.divisible.by.6': 'Bor vagy pezsgő esetében a megrendelt mennyiségnek 6-tal oszthatónak kell lennie.',
    'b2b-order.discounts.not.applicable': 'A beszállító partner letiltotta a kedvezményes árakat, így csak az akciós árak érhetők el.',
    'b2b-order.locks.modal.title': 'Termék foglalás/kiszedés',
    'b2b-order.has.no.price': 'Hiányzik az ár',
    'b2b-order.has.no.price.message': 'Ennek a terméknek még nincs megadva ár.',
    'b2b-order.has.no.price.link': 'A Vevő partner árak',
    'b2b-order.has.no.price.instructions': ' menüpont alatt válaszd ki ezt a vevő partnert a legördülő menüben. utána keresd meg a terméket az EAN vagy SKU azonosító alapján és állíts be egy árat a folytatáshoz.',
    'b2b-order.fifo.modal.close.button': 'Mégse',
    'b2b-order.fifo.modal.close': 'Bezár',
    'b2b-order.fifo.modal.save.button': 'Mentés',
    'b2b-order.fifo.modal.confirm': 'Megerősít',
    'b2b-order.allow-zero.modal.close.button': 'Mégse',
    'b2b-order.allow-zero.modal.add-product.button': 'Engedélyez',
    'b2b-order.allow-zero.lock.modal.close.button': 'Mégse',
    'b2b-order.allow-zero.lock.modal.add-product.button': 'Engedélyez',
    'b2b-order.stock-product-locks.modal.type.available': 'Szabad',
    'b2b-order.stock-product-locks.modal.type.cp-reservation': 'Partner foglalás',
    'b2b-order.stock-product-locks.modal.type.ch-reservation': 'Csatorna foglalás',
    'b2b-order.error.message.required': 'Hiányzó adat(ok)!',
    'b2b-order.error.message.MissingAmountOnPlanItem': 'Hiányzó mennyiség a terméken.\n\nKérlek add meg a mennyiséget darabra vagy ha nincs megadva a termék oldalán add meg a [Palack / karton "db", Karton / sor "db", Karton sor / raklap "db"] kiegészítő adatokat!',
};
