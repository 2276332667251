"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.adminMenu = void 0;
var permissions_1 = require("../../libs/permissions/permissions");
var special_permissions_1 = require("../../libs/permissions/special-permissions");
var getPermissionsForMenuGroup = function (group) {
    var e_1, _a;
    var permissions = [];
    try {
        for (var _b = __values(Object.values(group !== null && group !== void 0 ? group : {})), _c = _b.next(); !_c.done; _c = _b.next()) {
            var value = _c.value;
            if (!(value === null || value === void 0 ? void 0 : value.read)) {
                continue;
            }
            permissions.push(value.read);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return permissions;
};
exports.adminMenu = [
    {
        name: 'dashboard',
        path: '/',
        icon: 'fa fa-user-chart',
        activeRegexp: /^\/$/,
        items: [],
        permissions: [permissions_1.PERMISSIONS.DASHBOARD.read]
    },
    {
        name: 'sales-dashboard',
        path: '/sales-dashboard',
        icon: 'fa fa-user-chart',
        activeRegexp: /^\/sales-dashboard/,
        items: [],
        permissions: [permissions_1.PERMISSIONS.DASHBOARD.read]
    },
    {
        name: 'products',
        path: '/products',
        icon: 'fa fa-wine-bottle',
        activeRegexp: /^\/products/,
        items: [],
        permissions: [permissions_1.PERMISSIONS.PRODUCTS.read]
    },
    {
        name: 'crm',
        path: '/crm',
        icon: 'fas fa-users',
        activeRegexp: /^\/crm/,
        permissions: getPermissionsForMenuGroup(permissions_1.PERMISSIONS.CRM),
        items: [
            {
                name: 'customers',
                path: '/customers',
                icon: '',
                activeRegexp: /^\/customers/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.CRM.customers.read]
            },
            {
                name: 'supplier-partner',
                path: '/supplier-partner',
                icon: '',
                activeRegexp: /^\/supplier-partner/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.CRM.supplierPartners.read],
            },
            {
                name: 'customer-partner',
                path: '/customer-partner',
                icon: '',
                activeRegexp: /^\/customer-partner$/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.CRM.customerPartners.read],
            },
            {
                name: 'search-keywords',
                path: '/search-keywords',
                icon: '',
                activeRegexp: /^\/search-keywords/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.CRM.searchKeywords.read],
            },
            {
                name: 'complaints',
                path: '/complaints',
                icon: '',
                activeRegexp: /^\/complaints/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.CRM.complaints.read],
            },
            {
                name: 'newsletter-subscriber',
                path: '/newsletter-subscriber',
                icon: '',
                activeRegexp: /^\/newsletter-subscriber/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.CRM.newsletterSubscriber.read],
            },
            {
                name: 'coupons',
                path: '/coupons',
                icon: '',
                activeRegexp: /^\/coupons/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.CRM.coupons.read],
            },
            {
                name: 'stock-shortage-subscriptions',
                path: '/stock-shortage-subscriptions',
                icon: '',
                activeRegexp: /^\/stock-shortage-subscriptions/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.CRM.stockShortageSubscriptions.read],
            },
        ],
    },
    {
        name: 'stocks',
        path: '/stocks',
        icon: 'fa-solid fa-warehouse-full',
        activeRegexp: /^\/stocks/,
        permissions: getPermissionsForMenuGroup(permissions_1.PERMISSIONS.STORAGE),
        items: [
            {
                name: 'procurement',
                path: '/procurement',
                activeRegexp: /^\/procurement/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.STORAGE.procurement.read],
            },
            {
                name: 'b2b-stock',
                path: '/b2b-stock',
                icon: '',
                activeRegexp: /^\/b2b-stock$/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.STORAGE.actualStock.read],
            },
            {
                name: 'b2b-kn-stock',
                path: '/b2b-kn-stock',
                icon: '',
                activeRegexp: /^\/b2b-kn-stock$/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.STORAGE.knStock.read],
            },
            {
                name: 'b2b-stock-history',
                path: '/b2b-stock-history',
                icon: '',
                activeRegexp: /^\/b2b-stock-history$/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.STORAGE.stockHistory.read],
            },
            {
                name: 'stock-income',
                path: '/stock-income',
                activeRegexp: /^\/stock-income/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.STORAGE.stockIncome.read],
            },
            {
                name: 'stock-issuing',
                path: '/stock-issuing',
                activeRegexp: /^\/stock-issuing/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.STORAGE.stockIssue.read],
            },
            {
                name: 'stock-transaction',
                path: '/stock-transaction',
                activeRegexp: /^\/stock-transaction/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.STORAGE.stockTransaction.read],
            },
            {
                name: 'customer-partner-stock-reservation',
                path: '/customer-partner-stock-reservation',
                activeRegexp: /^\/customer-partner-stock-reservation/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.STORAGE.partnerStockReservation.read],
            },
            {
                name: 'channel-stock-reservation',
                path: '/channel-stock-reservation',
                activeRegexp: /^\/channel-stock-reservation/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.STORAGE.channelStockReservation.read],
            },
            {
                name: 'stock-disposal',
                path: '/stock-disposal',
                activeRegexp: /^\/stock-disposal/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.STORAGE.stockDisposable.read],
            },
            {
                name: 'stock-product-inventory',
                path: '/stock-product-inventory',
                activeRegexp: /^\/stock-product-inventory/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.STORAGE.stockProductInventory.read],
            },
            {
                name: 'anyk',
                path: '/anyk',
                activeRegexp: /^\/anyk/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.STORAGE.anyk.read],
            },
        ]
    },
    {
        name: 'pricing',
        path: '/pricing',
        icon: 'fa fa-circle-dollar',
        activeRegexp: /^\/pricing/,
        permissions: getPermissionsForMenuGroup(permissions_1.PERMISSIONS.PRICING),
        items: [
            {
                name: 'supplier-pricing',
                path: '/b2b-supplier-pricing',
                activeRegexp: /^\/b2b-supplier-pricing/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.PRICING.supplierPricing.read],
            },
            {
                name: 'channel-pricing',
                path: '/b2b-channel-pricing',
                activeRegexp: /^\/b2b-channel-pricing/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.PRICING.channelPricing.read],
            },
            {
                name: 'customer-partner-pricing',
                path: '/b2b-customer-partner-pricing',
                activeRegexp: /^\/b2b-customer-partner-pricing/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.PRICING.customerPartnerPricing.read],
            }
        ]
    },
    {
        name: 'orders',
        path: '/orders',
        icon: 'fa fa-shopping-cart',
        activeRegexp: /^\/orders/,
        permissions: getPermissionsForMenuGroup(permissions_1.PERMISSIONS.ORDERS),
        items: [
            {
                name: 'b2c-order',
                path: '/orders',
                icon: '',
                activeRegexp: /^\/orders/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.ORDERS.b2cOrders.read],
            },
            {
                name: 'b2b-order',
                path: '/b2b-order',
                activeRegexp: /^\/b2b-order/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.ORDERS.b2bOrders.read],
            },
        ]
    },
    {
        name: 'reports',
        path: '/reports',
        icon: 'fa fa-chart-mixed',
        activeRegexp: /^\/reports/,
        permissions: getPermissionsForMenuGroup(permissions_1.PERMISSIONS.REPORTS),
        items: [
            {
                name: 'order-sales-report',
                path: '/order-sales-report',
                icon: '',
                activeRegexp: /^\/order-sales-report/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.REPORTS.orderSalesReport.read],
            },
            {
                name: 'product-sales-report',
                path: '/product-sales-report',
                icon: '',
                activeRegexp: /^\/product-sales-report/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.REPORTS.productSalesReport.read],
            },
            {
                name: 'supplier-stock-report',
                path: '/supplier-stock-report',
                icon: '',
                activeRegexp: /^\/supplier-stock-report/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.REPORTS.supplierStockReport.read],
            },
            {
                name: 'product-sales-by-orders-report',
                path: '/product-sales-by-orders-report',
                icon: '',
                activeRegexp: /^\/product-sales-by-orders-report/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.REPORTS.productSalesByOrders.read],
            },
            {
                name: 'stock-rotation-report',
                path: '/stock-rotation-report',
                icon: '',
                activeRegexp: /^\/stock-rotation-report/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.REPORTS.stockRotationReport.read],
            },
            {
                name: 'customer-partner-report',
                path: '/customer-partner-report',
                icon: '',
                activeRegexp: /^\/customer-partner-report/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.REPORTS.customerPartnerReport.read],
            }
        ]
    },
    {
        name: 'settings',
        path: '/settings',
        icon: 'far fa-sliders',
        activeRegexp: /^\/settings/,
        permissions: getPermissionsForMenuGroup(permissions_1.PERMISSIONS.SETTINGS),
        items: [
            {
                name: 'user',
                path: '/user',
                icon: '',
                activeRegexp: /^\/user/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.SETTINGS.users.read],
            },
            {
                name: 'sales-targets',
                path: '/sales-targets',
                icon: '',
                activeRegexp: /^\/sales-targets/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.SETTINGS.salesTargets.read],
            },
            {
                name: 'shipping-charge',
                path: '/shipping-charge',
                icon: '',
                activeRegexp: /^\/shipping-charge/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.SETTINGS.salesTargets.read],
            },
            {
                name: 'attributes',
                path: '/attributes',
                icon: '',
                activeRegexp: /^\/attributes/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.SETTINGS.attributes.read],
            },
            {
                name: 'kn-attributes',
                path: '/kn-attributes',
                icon: '',
                activeRegexp: /^\/kn-attributes/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.SETTINGS.knAttributes.read],
            },
            {
                name: 'categories',
                path: '/categories',
                icon: '',
                activeRegexp: /^\/categories/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.SETTINGS.categories.read],
            },
            {
                name: 'region',
                path: '/region',
                icon: '',
                activeRegexp: /^\/region/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.SETTINGS.regions.read],
            },
            {
                name: 'winery',
                path: '/winery',
                icon: '',
                activeRegexp: /^\/winery/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.SETTINGS.winery.read],
            },
            {
                name: 'slider',
                path: '/slider',
                icon: '',
                activeRegexp: /^\/slider/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.SETTINGS.slider.read],
            },
            {
                name: 'main-menu-settings',
                path: '/main-menu-settings',
                icon: '',
                activeRegexp: /^\/main-menu-settings/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.SETTINGS.mainMenuSettings.read],
            },
            {
                name: 'product-orders',
                path: '/product-orders',
                icon: '',
                activeRegexp: /^\/product-orders/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.SETTINGS.productOrders.read],
            },
            {
                name: 'depot',
                path: '/b2b-depot',
                activeRegexp: /^\/b2b-depot/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.SETTINGS.b2bDepot.read],
            },
            {
                name: 'warehouse',
                path: '/b2b-warehouse',
                activeRegexp: /^\/b2b-warehouse/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.SETTINGS.b2bWarehouse.read],
            },
            {
                name: 'inventory',
                path: '/b2b-inventory',
                activeRegexp: /^\/b2b-inventory/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.SETTINGS.b2bInventory.read],
            },
            {
                name: 'storage',
                path: '/b2b-storage',
                activeRegexp: /^\/b2b-storage/,
                items: [],
                permissions: [permissions_1.PERMISSIONS.SETTINGS.b2bStorage.read],
            },
        ]
    },
    {
        name: 'other',
        path: '/other',
        icon: 'fa fa-box-archive',
        activeRegexp: /^\/other/,
        permissions: [permissions_1.PERMISSIONS.MISC.read],
        items: [
            {
                name: 'change-log',
                path: '/change-log',
                icon: '',
                activeRegexp: /^\/change-log$/,
                items: [],
                permissions: [],
            },
            {
                name: 'stock',
                path: '/stock',
                icon: '',
                activeRegexp: /^\/stock$/,
                items: [],
                permissions: [],
            },
            {
                name: 'stock-site',
                path: '/stock-site',
                icon: '',
                activeRegexp: /^\/stock-site$/,
                items: [],
                permissions: [],
            },
            {
                name: 'stock-history',
                path: '/stock-history',
                icon: '',
                activeRegexp: /^\/stock-history$/,
                items: [],
                permissions: [],
            },
            {
                name: 'vam-informatika',
                path: '/vam-informatika',
                icon: '',
                activeRegexp: /^\/vam-informatika$/,
                items: [],
                permissions: [special_permissions_1.SPECIAL_PERMISSIONS.vamInformatics],
            },
            {
                name: 'dpd',
                path: '/dpd',
                icon: '',
                activeRegexp: /^\/dpd$/,
                items: [],
                permissions: [special_permissions_1.SPECIAL_PERMISSIONS.dpd],
            },
            {
                name: 'stock-storage',
                path: '/stock-storage',
                icon: '',
                activeRegexp: /^\/stock-storage$/,
                items: [],
                permissions: [],
            },
            {
                name: 'pricing',
                path: '/pricing',
                icon: '',
                activeRegexp: /^\/pricing/,
                permissions: [],
                items: []
            },
        ]
    }
];
