"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bOrderCreateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var checkbox_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/checkbox.form-control-type");
var datepicker_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/datepicker.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var checkbox_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/checkbox-input");
var datepicker_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/datepicker/datepicker");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment_1 = require("moment/moment");
var React = require("react");
var sticky_container_1 = require("../../common/components/sticky-container");
var user_permission_1 = require("../../common/libs/user-permission");
var form_1 = require("../../components/form/form");
var handle_toasts_1 = require("../../components/libs/handle.toasts");
var message_box_1 = require("../../components/libs/message-box/message-box");
var modal_1 = require("../../components/modal/modal");
require("./b2b-order.scss");
var add_product_modal_1 = require("./components/add-product.modal");
var b2b_order_product_table_row_1 = require("./components/b2b-order-product-table-row");
var delete_product_modal_1 = require("./components/delete-product.modal");
var total_amount_1 = require("./components/total-amount");
var B2bOrderCreateScreen = (function (_super) {
    __extends(B2bOrderCreateScreen, _super);
    function B2bOrderCreateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.productsRefs = {};
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            products: [],
            loading: false,
            error: '',
            shippingChargeOptions: {},
            shippingCharge: 0,
            isCpSelected: false,
            isFreeShipping: false,
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                customerPartner: new field_1.Field({
                    name: 'customerPartner',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('b2b-order.form.label.customer-partner'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: '',
                    optionsEndpoint: '/b2b/customer-partner?sort[name]=1&isActive=true',
                    optionsMap: function (response) {
                        var _a;
                        return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({
                            name: "".concat(item.name, " (").concat(item.brandName, ")"),
                            value: { id: item._id, channels: item.channels },
                            key: item._id,
                        }); });
                    },
                    loadOptionsAfterMount: true,
                    validators: [
                        new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))
                    ]
                }),
                shippingData: new field_1.Field({
                    name: 'shippingData',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('b2b-order.form.label.shipping-data'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    value: '',
                    options: [],
                    loadOptionsAfterMount: true,
                    validators: [
                        new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))
                    ]
                }),
                customOrderNumber: new field_1.Field({
                    name: 'customOrderNumber',
                    id: 'customOrderNumber',
                    value: '',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.customOrderNumber')),
                    validators: []
                }),
                goodsReceiptId: new field_1.Field({
                    name: 'goodsReceiptId',
                    id: 'goodsReceiptId',
                    value: '',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.goodsReceiptId')),
                    validators: []
                }),
                comment: new field_1.Field({
                    name: 'comment',
                    id: 'comment',
                    value: '',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.comment')),
                    validators: []
                }),
                publicComment: new field_1.Field({
                    name: 'publicComment',
                    id: 'publicComment',
                    value: '',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.publicComment')),
                    validators: []
                }),
                licensePlateNumber: new field_1.Field({
                    name: 'licensePlateNumber',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.licensePlateNumber')),
                    value: '',
                    validators: []
                }),
                plannedDeliveryDate: new field_1.Field({
                    name: 'plannedDeliveryDate',
                    value: '',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.plannedDeliveryDate')),
                }),
                issuingPurpose: new field_1.Field({
                    name: 'issuingPurpose',
                    id: 'issuingPurpose',
                    value: '',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.issuingPurpose')),
                    validators: []
                }),
                isForeign: new field_1.Field({
                    name: 'isForeign',
                    id: 'isForeign',
                    value: false,
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.isForeign')),
                    validators: []
                }),
                isFreeShipping: new field_1.Field({
                    name: 'isFreeShipping',
                    id: 'isFreeShipping',
                    value: false,
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.isFreeShipping')),
                    validators: []
                }),
            }
        });
        return _this;
    }
    B2bOrderCreateScreen.prototype.componentDidMount = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var channels;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4, this.fetchShippingCharges()];
                    case 1:
                        _c.sent();
                        channels = (((_b = (_a = this.form.fields.customerPartner) === null || _a === void 0 ? void 0 : _a.getValue()) === null || _b === void 0 ? void 0 : _b.channels) || []);
                        void this.setUpCache(channels);
                        this.$subscriptions.push(this.form.fields.customerPartner.$value.subscribe(function (value) {
                            _this.onCustomerPartnerChange(value);
                            void _this.setUpCache(value === null || value === void 0 ? void 0 : value.channels);
                        }), this.form.fields.shippingData.$value.subscribe(function (shippingData) { return _this.setState({
                            zipCode: shippingData === null || shippingData === void 0 ? void 0 : shippingData.zipCode
                        }); }), this.form.fields.isFreeShipping.$value.subscribe(function (isFreeShipping) { return _this.setState({ isFreeShipping: isFreeShipping }); }));
                        return [2];
                }
            });
        });
    };
    B2bOrderCreateScreen.prototype.onCustomerPartnerChange = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetchCustomerPartner()];
                    case 1:
                        _a.sent();
                        return [4, this.form.fields.shippingData.setValue(null)];
                    case 2:
                        _a.sent();
                        this.setState({ isCpSelected: !!value, products: [] });
                        return [2];
                }
            });
        });
    };
    B2bOrderCreateScreen.prototype.fetchCustomerPartner = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var cpId, response, list, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 4, , 5]);
                        return [4, this.form.fields.customerPartner.getValue()];
                    case 1:
                        cpId = _c.sent();
                        if (!cpId) {
                            this.setState({ partner: null });
                            return [2, this.form.fields.supplierShippingData.setOptions([])];
                        }
                        return [4, this.repository.get("/b2b/customer-partner/".concat(cpId.id))];
                    case 2:
                        response = _c.sent();
                        list = ((_a = response === null || response === void 0 ? void 0 : response.shippingDatas) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                            var operatingPermitNumber = item.operatingPermitNumber ? "(".concat(item.operatingPermitNumber, ")") : '';
                            return {
                                name: "".concat(item.zipCode, " ").concat(item.city, ", ").concat(item.address, " ").concat(operatingPermitNumber),
                                value: item,
                                key: item.name
                            };
                        })) || [];
                        return [4, this.form.fields.shippingData.setOptions(list)];
                    case 3:
                        _c.sent();
                        return [3, 5];
                    case 4:
                        e_1 = _c.sent();
                        console.log((_b = e_1 === null || e_1 === void 0 ? void 0 : e_1.message) !== null && _b !== void 0 ? _b : 'generic error');
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    B2bOrderCreateScreen.prototype.render = function () {
        return React.createElement("div", { className: 'B2bOrderScreen B2bOrderCreateScreen' },
            this.renderModals(),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col' },
                    React.createElement("div", { className: 'mb-2' },
                        React.createElement(button_1.Button, { className: 'button-neutral-link button-size-normal mb-1', title: 'Vissza', iconLeft: 'fas fa-arrow-left', onClick: function () { return router_provider_1.RouterProvider.goTo('/b2b-order'); } })),
                    React.createElement("div", { className: 'mb-4' },
                        React.createElement("h3", { className: 'fw-600' }, (0, trans_1.trans)('b2b-order.create.title'))),
                    React.createElement(form_1.Form, null,
                        React.createElement("div", { className: 'row' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.isForeign, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic col-6' }))),
                        this.renderFreeShipping(),
                        React.createElement("div", { className: 'row' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.customerPartner, type: select_input_form_control_type_1.SelectInputFormControlType },
                                React.createElement(select_input_1.SelectInput, { unclearable: false, multiple: false, searchable: true, className: 'select-input-basic col-12' })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.shippingData, type: select_input_form_control_type_1.SelectInputFormControlType },
                                React.createElement(select_input_1.SelectInput, { disabled: !this.state.isCpSelected, unclearable: false, multiple: false, className: 'select-input-basic col-12' }))),
                        React.createElement("div", { className: 'row' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.customOrderNumber, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { className: 'text-input-basic col-12' })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.goodsReceiptId, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { className: 'text-input-basic col-12' }))),
                        React.createElement("div", { className: 'row' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.licensePlateNumber, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(text_input_1.TextInput, { readOnly: this.state.readOnly, className: 'text-input-basic col-12' })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.plannedDeliveryDate, type: datepicker_form_control_type_1.DatepickerFormControlType },
                                React.createElement(datepicker_1.Datepicker, { className: 'datepicker-basic col-12', readOnly: this.state.readOnly, settings: { enabledDateFrom: (0, moment_1.utc)().format('YYYY.MM.DD') }, type: 'date', locale: 'hu' }))),
                        React.createElement("div", { className: 'row' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.issuingPurpose, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(textarea_input_1.TextareaInput, { rows: 6, className: 'textarea-input-basic col-12', readOnly: this.state.readOnly })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.comment, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(textarea_input_1.TextareaInput, { rows: 6, className: 'textarea-input-basic col-12' }))),
                        React.createElement("div", { className: 'row' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.publicComment, type: text_input_form_control_type_1.TextInputFormControlType },
                                React.createElement(textarea_input_1.TextareaInput, { rows: 6, className: 'textarea-input-basic col-12' }))))),
                React.createElement("div", { className: 'col save-box-wrapper' }, this.renderSaveBox())),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col' }, this.renderProductsTable())));
    };
    B2bOrderCreateScreen.prototype.renderProductsTable = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("div", { className: 'col mb-3 mt-6 display-flex align-items-center' },
                React.createElement("h5", { className: 'mr-6' }, (0, trans_1.trans)('b2b-order.create.product-list.title')),
                React.createElement(button_1.Button, { title: (0, trans_1.trans)('b2b-order.create.product-list.add.button'), iconLeft: 'fal fa-plus', onClick: function () { return _this.$showAddProductModal.open(); }, className: 'button-primary-inverze button-size-normal', disabled: !this.state.isCpSelected })),
            React.createElement(table_1.Table, { className: 'table-basic B2bOrderTable mb-6', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return _this.renderItems(); } }),
            React.createElement(total_amount_1.TotalAmount, { onShippingCalculation: function (shippingCharge) { return _this.setState({ shippingCharge: shippingCharge }); }, shippingChargeOptions: this.state.shippingChargeOptions, prices: this.state.prices, zipCode: this.state.zipCode, isFreeShipping: this.state.isFreeShipping }));
    };
    B2bOrderCreateScreen.prototype.handlePlanChange = function (productId, amount, price, isBestPrice, drs) {
        var _a;
        var _b, _c, _d, _e;
        this.setState({
            prices: __assign(__assign({}, this.state.prices), (_a = {}, _a[productId] = {
                drs: drs,
                amount: +(amount !== null && amount !== void 0 ? amount : 0),
                price: +(price || ((_e = (_d = (_c = (_b = this.state) === null || _b === void 0 ? void 0 : _b.prices) === null || _c === void 0 ? void 0 : _c[productId]) === null || _d === void 0 ? void 0 : _d.bestPrice) !== null && _e !== void 0 ? _e : 0)),
                bestPrice: this.getBestPrice({ isBestPrice: isBestPrice, price: price, productId: productId })
            }, _a))
        });
    };
    B2bOrderCreateScreen.prototype.getBestPrice = function (_a) {
        var _b, _c, _d, _e;
        var isBestPrice = _a.isBestPrice, price = _a.price, productId = _a.productId;
        if (isBestPrice || "".concat(price) === '0' || +price > 0) {
            return +price;
        }
        return (_e = (_d = (_c = (_b = this.state) === null || _b === void 0 ? void 0 : _b.prices) === null || _c === void 0 ? void 0 : _c[productId]) === null || _d === void 0 ? void 0 : _d.bestPrice) !== null && _e !== void 0 ? _e : 0;
    };
    B2bOrderCreateScreen.prototype.renderItems = function () {
        var _this = this;
        var _a;
        if (!this.state.products.length) {
            return React.createElement("div", { className: 'display-flex justify-content-center py-7' },
                React.createElement("p", { className: 'fw-700' }, "Nincs m\u00E9g hozz\u00E1adva term\u00E9k"));
        }
        return (_a = this.state.products) === null || _a === void 0 ? void 0 : _a.map(function (item) {
            return React.createElement(b2b_order_product_table_row_1.B2bOrderProductTableRow, { onPlanChange: function (amount, price, isBestPrice, drs) { return _this.handlePlanChange(item.productId, amount, price, isBestPrice, drs); }, key: item.productId, ref: function (ref) { return _this.productsRefs[item.productId] = ref; }, showDeleteProductModal: function () { return _this.$showDeleteProductModal.open({ product: item.productId }); }, flow: 'create', product: item, customerPartner: _this.form.fields.customerPartner.getValue() });
        });
    };
    B2bOrderCreateScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 25 }, this.setHeaderCell('product'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('bestPrice'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 12 }, this.setHeaderCell('exactPrice'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('unit'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 18 }, this.setHeaderCell('plan'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('casePicked'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('fact'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 5 }, this.setHeaderCell('delete'), { className: 'justify-content-center' })));
    };
    B2bOrderCreateScreen.prototype.setHeaderCell = function (p, enableSort) {
        if (enableSort === void 0) { enableSort = false; }
        return {
            enableSort: enableSort,
            title: (0, trans_1.trans)("b2b-order.create.table.header.".concat(p)),
            property: p
        };
    };
    B2bOrderCreateScreen.prototype.renderSaveBox = function () {
        var _this = this;
        return React.createElement(sticky_container_1.StickyContainer, { topSpacing: 32, className: 'w-100' },
            React.createElement("div", { className: 'save-box mb-6' },
                React.createElement("h5", { className: 'mb-6' }, "L\u00C9TREHOZ\u00C1S"),
                React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Rögzítés', iconLeft: this.state.loading ? '' : 'fal fa-plus', className: 'button-primary-normal button-size-normal', disabled: false, onClick: function () { return _this.createOrder(); } })),
            this.state.error && React.createElement(message_box_1.MessageBox, { type: 'error', message: React.createElement("div", { className: 'display-flex flex-column align-items-start' }, this.state.error) }));
    };
    B2bOrderCreateScreen.prototype.createOrder = function () {
        return __awaiter(this, void 0, void 0, function () {
            var valid, items, _a, _b, product, item, e_2_1, getCalculatedPrice_1, formData, isFreeShipping, data, response, e_3, errorMessage;
            var e_2, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 11, , 12]);
                        if (this.state.loading) {
                            return [2];
                        }
                        this.setState({ loading: true });
                        return [4, this.form.validate()];
                    case 1:
                        valid = _d.sent();
                        items = [];
                        _d.label = 2;
                    case 2:
                        _d.trys.push([2, 7, 8, 9]);
                        _a = __values(this.state.products), _b = _a.next();
                        _d.label = 3;
                    case 3:
                        if (!!_b.done) return [3, 6];
                        product = _b.value;
                        return [4, this.productsRefs[product.productId].getData()];
                    case 4:
                        item = _d.sent();
                        if (item === null || item === void 0 ? void 0 : item.error) {
                            valid = false;
                            return [3, 5];
                        }
                        items.push(item);
                        _d.label = 5;
                    case 5:
                        _b = _a.next();
                        return [3, 3];
                    case 6: return [3, 9];
                    case 7:
                        e_2_1 = _d.sent();
                        e_2 = { error: e_2_1 };
                        return [3, 9];
                    case 8:
                        try {
                            if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7];
                    case 9:
                        if (!valid) {
                            this.setState({ loading: false });
                            throw new Error('required');
                        }
                        getCalculatedPrice_1 = function (_a) {
                            var exactPrice = _a.exactPrice, bestPrice = _a.bestPrice;
                            if ("".concat(exactPrice) === '0' || +exactPrice > 0) {
                                return exactPrice;
                            }
                            if (typeof bestPrice === 'object' && !isNaN(+(bestPrice === null || bestPrice === void 0 ? void 0 : bestPrice.bestPrice))) {
                                return bestPrice === null || bestPrice === void 0 ? void 0 : bestPrice.bestPrice;
                            }
                            if (!isNaN(+bestPrice)) {
                                return bestPrice;
                            }
                            throw new Error('Something is not right with the price given');
                        };
                        formData = this.form.toJSON();
                        isFreeShipping = this.form.fields.isFreeShipping.getValue();
                        data = __assign({ isFreeShipping: isFreeShipping, shippingCharge: this.getShippingCharge(), goodsReceiptId: formData.goodsReceiptId, publicComment: formData.publicComment, customerPartner: formData.customerPartner.id, shippingData: formData.shippingData, customOrderNumber: formData.customOrderNumber, comment: formData.comment, licensePlateNumber: formData.licensePlateNumber, plannedDeliveryDate: formData.plannedDeliveryDate, isForeign: formData.isForeign, issuingPurpose: formData.issuingPurpose }, (items.length
                            ? {
                                plan: items.map(function (i) {
                                    var _a;
                                    return ({
                                        productId: i.id,
                                        priceArtifact: i.priceArtifact,
                                        supplierPriceArtifact: i.supplierPriceArtifact,
                                        calculatedPrice: getCalculatedPrice_1(i),
                                        exactPrice: (_a = parseInt(i.exactPrice, 10)) !== null && _a !== void 0 ? _a : null,
                                        amount: parseInt(i.plan, 10),
                                        listPrice: i.listPrice,
                                        unit: i.unit,
                                        units: i.units,
                                    });
                                })
                            }
                            : ''));
                        return [4, this.repository.post('/b2b/order', { data: data })];
                    case 10:
                        response = _d.sent();
                        this.setState({ loading: false });
                        router_provider_1.RouterProvider.goTo("/b2b-order/".concat(response._id));
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('b2b-order.create.success.toast'));
                        return [3, 12];
                    case 11:
                        e_3 = _d.sent();
                        errorMessage = 'b2b-order.update.error.toast';
                        switch (e_3.message) {
                            case 'MissingLegalCode':
                                errorMessage = 'b2b-order.update.error.toast.MissingLegalCode';
                                break;
                            case 'MissingSupplierPrice':
                                errorMessage = 'b2b-order.update.error.toast.MissingSupplierPrice';
                                break;
                        }
                        handle_toasts_1.HandleToasts.error(e_3, (0, trans_1.trans)(errorMessage));
                        this.setState({ loading: false, error: (0, trans_1.trans)("b2b-order.error.message.".concat(e_3.message)) });
                        console.log(e_3);
                        return [3, 12];
                    case 12: return [2];
                }
            });
        });
    };
    B2bOrderCreateScreen.prototype.renderFreeShipping = function () {
        var _a, _b;
        var role = (_b = (_a = this.state.user) === null || _a === void 0 ? void 0 : _a.publicMeta) === null || _b === void 0 ? void 0 : _b.role;
        if (role !== user_permission_1.Role.storekeeper && role !== user_permission_1.Role.superadmin) {
            return null;
        }
        return React.createElement("div", { className: 'row' },
            React.createElement(form_control_1.FormControl, { field: this.form.fields.isFreeShipping, type: checkbox_form_control_type_1.CheckboxFormControlType },
                React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic col-6', disabled: this.state.readOnly })));
    };
    B2bOrderCreateScreen.prototype.renderModals = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showAddProductModal = ref); }, renderModalHeader: function () { return React.createElement("h4", null, (0, trans_1.trans)('b2b-order.add-product.modal.title')); }, renderModalContent: function (ctx) {
                    var _a, _b, _c, _d;
                    return React.createElement(add_product_modal_1.AddProductModal, { onClose: function () { return _this.$showAddProductModal.close(); }, onAddProduct: function (product) { return _this.setState({ products: __spreadArray(__spreadArray([], __read(_this.state.products), false), [product], false), }); }, customerPartnerChannels: (_d = (_c = (((_b = (_a = _this.form.fields.customerPartner) === null || _a === void 0 ? void 0 : _a.getValue()) === null || _b === void 0 ? void 0 : _b.channels) || [])) === null || _c === void 0 ? void 0 : _c.map(function (ch) { return "salesChannels[]=".concat(ch); })) === null || _d === void 0 ? void 0 : _d.join('&'), excludedProductsQuery: (_this.state.products || []).map(function (p) { return "excludedProducts[]=".concat(p.product._id); }).join('&') });
                } }),
            React.createElement(modal_1.Modal, { ref: function (ref) { return (_this.$showDeleteProductModal = ref); }, renderModalHeader: function () { return null; }, renderModalContent: function (ctx) {
                    var _a, _b;
                    return React.createElement(delete_product_modal_1.DeleteProductModal, { onClose: function () { return _this.$showDeleteProductModal.close(); }, onDeleteProductFromPlan: function (productId) { return _this.handleRemoveProduct(productId); }, product: (_b = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.args) === null || _b === void 0 ? void 0 : _b.product });
                } }));
    };
    B2bOrderCreateScreen.prototype.handleRemoveProduct = function (productId) {
        var _a;
        var newProducts = (_a = this.state.products) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.productId !== productId; });
        this.setState({ products: newProducts });
        this.productsRefs[productId] = null;
        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('b2b-order.create.delete.product.success.toast'));
        this.$showDeleteProductModal.close();
    };
    B2bOrderCreateScreen.prototype.getShippingCharge = function () {
        if (this.state.isFreeShipping) {
            return 0;
        }
        return this.state.shippingCharge;
    };
    B2bOrderCreateScreen.prototype.setUpCache = function (channelsArr) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var channels, endpoint, e_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        if (!(channelsArr === null || channelsArr === void 0 ? void 0 : channelsArr.length)) {
                            return [2];
                        }
                        channels = (_a = channelsArr === null || channelsArr === void 0 ? void 0 : channelsArr.map(function (ch) { return "salesChannels[]=".concat(ch); })) === null || _a === void 0 ? void 0 : _a.join('&');
                        endpoint = "/b2b/stock/list-by-customer-partner-with-price-for-order?".concat(channels);
                        return [4, this.repository.get(endpoint)];
                    case 1:
                        _b.sent();
                        return [3, 3];
                    case 2:
                        e_4 = _b.sent();
                        console.log({ setUpCache: e_4 });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    B2bOrderCreateScreen.prototype.fetchShippingCharges = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, e_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.repository.get('/b2b/shipping-charge')];
                    case 1:
                        response = _a.sent();
                        if (response) {
                            this.setState({ shippingChargeOptions: response });
                        }
                        return [3, 3];
                    case 2:
                        e_5 = _a.sent();
                        console.log({ fetchShippingCharges: e_5 });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    return B2bOrderCreateScreen;
}(abstract_component_1.AbstractComponent));
exports.B2bOrderCreateScreen = B2bOrderCreateScreen;
