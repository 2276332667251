"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomShippingCharge = void 0;
var CustomShippingCharge = (function () {
    function CustomShippingCharge(_a) {
        var cost = _a.cost, zipCodes = _a.zipCodes, minimumThresholdEur = _a.minimumThresholdEur, minimumThresholdHuf = _a.minimumThresholdHuf;
        this.minimumThresholdHuf = minimumThresholdHuf || '';
        this.minimumThresholdEur = minimumThresholdEur || '';
        this.cost = cost || '';
        this.key = this.generateHash();
        this.zipCodes = zipCodes || {
            from: '',
            to: '',
        };
    }
    CustomShippingCharge.prototype.generateHash = function () {
        return Math.random().toString(36).substring(2, 10);
    };
    return CustomShippingCharge;
}());
exports.CustomShippingCharge = CustomShippingCharge;
